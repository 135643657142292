@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@material-tailwind/react/tailwind.css';

/* hide scrollbar  */
::-webkit-scrollbar {
  display: none;
}

::-moz-scrollbar {
  display: none;
 
}



@font-face {
  font-family: 'Myriad';
  src: url('./fonts/Myriad-Black.otf');
}

@font-face {
  font-family: 'AstanaR';
  src: url('./fonts/Astana-Regular.otf');
}

@font-face {
  font-family: 'AstanaM';
  src: url('./fonts/Astana-Medium.otf');
}


@font-face {
  font-family: 'Astana';
  src: url('./fonts/Astana-Black.otf');
}

@layer base {
 .conditions h1 {
    @apply text-3xl;
  }
  .conditions h2 {
    @apply text-2xl py-6;
  }
  .conditions h3 {
    @apply text-xl py-4;
  }
  .conditions h4 {
    @apply text-lg py-2;
  }
}

body {
  height: 100%;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}

/******** hide scrollbar **********/
.neumorph {

  box-shadow: inset 1px 1px 1px #fff, -9px -9px 9px #e4e3e3,
    9px 9px 20px rgba(243, 243, 243, 0.8);
}


.dropdown:hover .dropdown-menu {
    display: block;
  }


  /* swipper carousel */

  /* div.swiper-button-prev { 
    background-image: url('http://127.0.0.1:8000/storage/carousel-button-prev.png') !important;
    background-repeat: no-repeat !important;
    width: 30px !important;
    height: 30px;
    background-position: center;
    background-size: contain;
    color:transparent;
  } */

  .swiper-button-prev {
    opacity: 0 !important;
  }
  
  .swiper-slide {
    opacity: 1;
  }
   #home.swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
  }

  /* div.swiper-button-next { 
    background-image: url('http://127.0.0.1:8000/storage/carousel-button-next.png') !important;
    background-repeat: no-repeat !important;
    width: 30px !important;
    height: 30px;
    background-position: center;
    background-size: contain;
    color:transparent;
  } */

  .swiper-button-next {
    position: absolute;
    top:0;
  }

/************/

/*********** Modal ********/
.backdrop {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.5);
  z-index:1;
}

.modal{
  max-width: 400px;
  margin: 0 auto;
  padding: 40px 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
}


/*****************/

  .outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  :root {
    --bg: rgb(255, 255, 255);
    --bg-accent: red;
    --text-color: rgb(9, 100, 9);
    --nav-size: 30px;
    --border: 1px solid grey;
    --border-radius:2px;
    --speed: 500ms;
  }

  ul{
    list-style: none;
    margin:0;
    padding:0;
  }

  a {
    text-decoration: none;
  }

  .navbar {
    border-top: var(--border);
    height:var(--nav-size);
    background-color: var(--bg);
    padding:0 1rem;
    border-bottom: var(--border);
  }

  .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content:flex-end;
  }

  /******************* categories dropdown ****/

  #categories {
    --bg:  #05223f;
    --bg-accent: #ffffff;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #d2d7db;
    --border-radius: 8px;
    --speed: 50ms; 
  }
  
  #categories ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  #categories a {
    color: var(--text-color);
    text-decoration: none;;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  #categories .navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    /* padding: 0 1rem; */
    /* border-bottom: var(--border); */
  }
  
  /* <ul> */
  #categories .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  /* <li> */
  /* #categories .nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    background-color:red;
    align-items: center;
    justify-content: center;
  } */
  
  /* Icon Button */
  #categories .icon-button {
    --button-size: calc(var(--nav-size) * 0.2);
    width: var(--button-size);
    height: var(--button-size);
    /* background-color: #ffffff; */
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
  }
  
  /* #categories .icon-button:hover {
    filter: brightness(1.2);
  }
  
  #categories .icon-button svg { 
    fill: var(--text-color);
    width: 40px;
    height: 40px;
  }
   */
  
  /* Dropdown Menu */
  
  #categories .dropdown {
    /* position: absolute; */
    /* top: 20vh; */
    /* width: 300px; */
    transform: translateX(0);
    /* background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius); */
    /* padding: 1rem;
    margin-left: 80px; */
    /* overflow: hidden; */
    transition: height var(--speed) ease;
    /* z-index: 50; */
  }
  
  #categories .menu {
    margin:0;
    padding:0;
    width: 100%;
  }
  
  #categories .menu-item {
    height: 30px;
    display: flex;
    align-items: left;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    margin-bottom: 0.4rem;
  }
  
  #categories .menu-item .icon-button {
    margin-right: 0.1rem;
  }
  
  
  /* #categories .menu-item .icon-button:hover {
    filter: none;
  }
  
  #categories .menu-item:hover {
    background-color: #909091;
  } */
  
  #categories .icon-right {
    --button-size: calc(var(--nav-size) * 0.4);
    width: var(--button-size);
    height: var(--button-size);
    background-color: 'transparent';
    border-radius: 50%;
    padding: 1px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    margin-left: auto;
    cursor: pointer;
  }

  #categories .icon-left {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color:'transparent';
    border-radius: 50%;
    padding: 1px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    margin-left: auto;
    cursor: pointer;
  }
  
  /* CSSTransition classes primary  */
  #categories .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  #categories .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  #categories .menu-primary-exit {
    position: absolute;
  }
  #categories .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
    /* CSSTransition classes secondary  */

  #categories .menu-secondary-enter {
    transform: translateX(110%);
  }
  #categories .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }

  #categories .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }


    /* #Mega Menu Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .mega-menu {
    display: none;
    left: 0;
    position: absolute;
    text-align: left;
    z-index: 50;
    width: 50%;
  }

  @media only screen and (max-width: 640px) {
    .mega-menu {
      width: 100%;
    }
  }

  /* #hoverable Class Styles
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .hoverable {
    position: static;
  }

  .hoverable:hover .mega-menu {
    display: block;
  }

.produi .swiper-container {
  width: 480px;
}
 
@media screen and (min-width: 640px) {
  .produi .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .produi .swiper-container {
    width: 768px;
  }
}